<template>
  <div class="router-wrap">
    <div class="aboutdefails">
      <div class="aboutTop"></div>
      <Header @aboutTop="getAboutHeader"></Header>
      <template v-if="topItem.index == 0">
        <Profile></Profile>
      </template>
      <template v-if="topItem.index == 1">
        <Development></Development>
      </template>
      <Footer></Footer>
    </div>
    <!-- 集团业务详情 -->
  </div>
</template>

<script>
import Header from "@/components/BusinessDetailsHeader";
import Profile from "@/components/Businesscorebusiness.vue";
import Development from "@/components/Businesscoreproducts.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "BusinessDetails",
  data() {
    return {
      topItem: {
        index: 0,
      },
    };
  },

  created() {
    let ind = sessionStorage.getItem("businessInd");
    if (ind !== null) {
      this.topItem = {
        index: ind,
      };
      console.log(this.topItem);
    }
  },

  components: {
    Header,
    Profile,
    Development,
    Footer,
  },
  methods: {
    getAboutHeader(data) {
      this.topItem = data;
    },
  },

  destroyed() {
    sessionStorage.removeItem("businessInd");
  },
};
</script>
<style lang="scss" scoped >
.aboutdefails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .aboutTop {
    width: 100%;
    height: 660px;
    background: url("../assets/imgs/business/businessbanner.jpg") center center;
  }
}
</style>