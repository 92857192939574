<template>
  <div class="Aboutheader-wrap">
    <div class="router-list">
      <div
        @click="aboutClick(item)"
        v-for="(item, ind) in data"
        :key="ind"
        :class="{ active: index == ind }"
      >
        {{ item.val }}
      </div>
    </div>
    <Back
      :name="langTrue == 'en-us' ? 'Group business' : '集团业务'"
      backtop="25px"
      backright="40px"
      homeBack="/HomePage"
      :homeName="langTrue == 'en-us' ? 'home page' : '首页'"
    />
  </div>
</template>
 <!-- borderBottom: index === ind ? '2px solid #0072cb' : '2px solid white', -->
<script>
import Back from "@/components/back";
export default {
  name: "header",
  data() {
    return {
      data: [
        {
          val:
            sessionStorage.getItem("langType") == "en-us"
              ? "Core business"
              : "核心业务",
          index: 0,
        },
        {
          val:
            sessionStorage.getItem("langType") == "en-us"
              ? "Core products"
              : "核心产品",
          index: 1,
        },
      ],
      index: 0,
      langTrue: sessionStorage.getItem("langType"),
    };
  },
  components: {
    Back,
  },
  watch: {},
  created() {
    let ind = sessionStorage.getItem("businessInd");
    if (ind !== null) this.index = ind;
  },
  mounted() {},
  methods: {
    aboutClick: function (item) {
      this.index = item.index;
      this.$emit("aboutTop", item);
    },
  },
};
</script>

<style scoped lang="scss">
.Aboutheader-wrap {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 95px;
  position: absolute;
  top: 660px;
  font-size: 16px;
  color: #444444;
  left: calc(50% - 600px);
  right: calc(50% - 600px);
  .router-list {
    width: 800px;
    display: flex;
    & > div {
      height: 50px;
      line-height: 50px;
      margin-right: 75px;
      cursor: pointer;
    }
    & > div:hover,
    & > div.active {
      color: #0072cb;
    }
    & > div::after {
      content: "";
      display: block;
      transition: all 200ms ease-in-out 0s;
      cursor: pointer;
      height: 2px;
      background: #0072cb;
      margin: 0 auto;
      width: 0;
    }
    & > div:hover::after,
    & > div.active::after {
      width: 100%;
    }
  }
}
</style>