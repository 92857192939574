<template>
  <div class="router-wrap">
    <div class="businessbox" v-if="data.length !== 0">
      <div class="businessboxchild" v-for="(item, ind) in data" :key="ind">
        <div class="businessbackbox">
          <img class="businessback" :src="item.url" />
        </div>

        <div class="businessvalue">
          <!-- <img class="businesstitleurl" :src="item.titleurl" /> -->
          <div class="profilejianjiebox">
            <!-- <img class="profilejianjie" :src="item.titleurl" /> -->
            <div class="profilejianjiewenzi">{{ item.title }}</div>
          </div>
          <div class="businesstitle">
            {{
              langTrue == "en-us" ? "outline" : "概要"
            }}
          </div>
          <div class="businessval" v-html="item.value">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div v-if="data.length == 0">
      <NoData></NoData>
    </div>
  </div>
</template>

<script>
import { articleList } from "@/api/common.js";
import NoData from "@/components/noData.vue";
import { baseURL } from "@/api/config.js";
export default {
  name: "BusinessDetails",
  data() {
    return {
      data: [
        {},
        // {
        //   titleurl: require("../assets/imgs/business/title.png"),
        //   title: "单用途预付消费卡 智能管理与服务",
        //   value: "",
        // },
      ],
      langTrue: sessionStorage.getItem("langType"),
    };
  },
  components: {
    NoData,
  },
  computed: {
    costRequest() {
      return {
        organizationType: this.activeTab,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.getarticleList();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
  },
  mounted() {
    this.getarticleList();
  },
  methods: {
    getAboutHeader(data) {
      this.topItem = data;
    },
    getarticleList() {
      articleList({
        lang: sessionStorage.getItem("langType"),
        categoryName: "核心业务",
      }).then((res) => {
        let data = [];
        res.data.map((v, i) => {
          data.push({
            titleurl: baseURL + v.coverImage,
            title: v.title,
            url: baseURL + v.extra3,
            value: v.content,
          });
        });
        this.data = data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.businessbox {
  width: 1200px;
  margin-top: 97px;
  .businessboxchild {
    width: 1200px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }
  .businessbackbox {
    width: 335px;
    margin: 11px 0 0 30px;
    overflow: hidden;
    display: inline-block;
  }
  .businessback {
    width: 335px;
    height: 428px;
    transition: transform 0.75s ease;
  }
  .businessback:hover {
    transform: scale(1.2, 1.2);
  }
  .businessvalue {
    width: 765px;
    display: inline-block;
    margin: 11px 0 0 45px;
    .profilejianjiebox {
      width: 100%;
      height: 95px;
      display: flex;
      align-items: center;
      .profilejianjie {
        width: 297px;
        height: 83px;
      }
      .profilejianjiewenzi {
        padding-left: 20px;
        // width: 220px;
        // height: 30px;
        border-left: 2px solid #1e83d1;
        line-height: 30px;
        text-align: center;
        font-size: 24px;
        color: #444444;
        // margin-left: 20px;
        margin-top: 15px;
        padding-left: 20px;
      }
    }
    .businesstitle {
      font-size: 16px;
      margin: 43px 0 25px 0;
      color: #444444;
    }
    .businessval {
      width: 730px;
      height: 240px;
      overflow-y: auto;
      padding-right: 20px;
    }
    .businessval::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    }
    .businessval::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 6px;
      background: #0072cb;
    }
    .businessval::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 6px;
      background: #eeeeee;
    }
  }
}
</style>
